import React from 'react';
import IndicatorDetailsChart from './IndicatorDetailsChart';
import IndicatorDynamicsChart from './IndicatorDynamicsChart';
import Title from '../../shared/components/Title';
import { useQuery } from 'react-query';
import { fetchIndicatorDetails } from '../../queries';
import { useParams } from 'react-router-dom';
import { ENABLE_PARTNERSHIPS } from '../../config';
import PartnershipChart from './PartnershipChart';
import ExcelDownloadButton from '../../shared/components/ExcelDownloadButton';
import IndicatorInfoSider from '../../shared/components/IndicatorInfo';
import { Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

const IndicatorView: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { data: indicator } = useQuery(['indicatorDetails', id], () => fetchIndicatorDetails(parseInt(id, 10)));
  const [visible, setVisible] = React.useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  return (
    <>
      <div className="title-wrapper">
        <Title title={indicator ? indicator.name : ''} />
        <div className="ant-col ant-col-4 buttons">
          <Button onClick={showDrawer} type="primary" className="ant-col details_btn" icon={<SearchOutlined />}>
            Szczególy
          </Button>
          <ExcelDownloadButton queryParams={{ level: 'indicator', indicatorId: id }} />
        </div>
        <IndicatorInfoSider visible={visible} setVisible={setVisible} data={indicator} />
      </div>
      {ENABLE_PARTNERSHIPS && <PartnershipChart indicator={indicator} />}
      <IndicatorDetailsChart indicator={indicator} />
      <IndicatorDynamicsChart />
    </>
  );
};

export default IndicatorView;
